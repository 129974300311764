export const WORDS = [
  'YYZ',
  'CKG',
  'XIY',
  'SYX',
  'HAK',
  'CGO',
  'PEK',
  'NAY',
  'SZX',
  'YOW',
  'KMG',
  'NGB',
  'CSX',
  'YVR',
  'TNA',
  'TYN',
  'FOC',
  'YQB',
  'NNG',
  'SHE',
  'YHZ',
  'YYC',
  'HGH',
  'HRB',
  'CTU',
  'CAN',
  'YYJ',
  'WNZ',
  'KWL',
  'SHA',
  'TSN',
  'URC',
  'XMN',
  'WUH',
  'DLC',
  'KWE',
  'NKG',
  'YWG',
  'YEG',
  'YUL',
  'PVG',
  'YXU',
]
